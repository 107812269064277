import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const LeshenSplitModule = withTheme(() => (
  <Global
    styles={css`
      .leshen-split-module {
        .module-area {
          .leshen-stack {
            align-items: unset;
          }
        }
      }
    `}
  />
))

export default LeshenSplitModule
