import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const TyprographyStyles = withTheme(() => (
  <Global
    styles={css`
      // unfortunatly these styles are necessary as they simply WOULD NOT work within theme.js

      .leshen-typography-h4,
      .leshen-typography-h5,
      .leshen-typography-h6 {
        font-family: figtree !important;
        font-weight: 400 !important;
      }
    `}
  />
))

export default TyprographyStyles
