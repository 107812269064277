exports.components = {
  "component---src-templates-city-jsx": () => import("./../../../src/templates/City.jsx" /* webpackChunkName: "component---src-templates-city-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/Default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-default-ppc-jsx": () => import("./../../../src/templates/DefaultPPC.jsx" /* webpackChunkName: "component---src-templates-default-ppc-jsx" */),
  "component---src-templates-dynamic-zip-packages-jsx": () => import("./../../../src/templates/DynamicZipPackages.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-jsx" */),
  "component---src-templates-one-trust-privacy-policy-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicy.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-jsx" */),
  "component---src-templates-one-trust-privacy-policy-spanish-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicySpanish.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-spanish-jsx" */),
  "component---src-templates-spanish-do-not-sell-jsx": () => import("./../../../src/templates/SpanishDoNotSell.jsx" /* webpackChunkName: "component---src-templates-spanish-do-not-sell-jsx" */),
  "component---src-templates-spanish-jsx": () => import("./../../../src/templates/Spanish.jsx" /* webpackChunkName: "component---src-templates-spanish-jsx" */),
  "component---src-templates-speed-test-jsx": () => import("./../../../src/templates/SpeedTest.jsx" /* webpackChunkName: "component---src-templates-speed-test-jsx" */),
  "component---src-templates-state-jsx": () => import("./../../../src/templates/State.jsx" /* webpackChunkName: "component---src-templates-state-jsx" */)
}

