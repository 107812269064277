import { withTheme } from '@emotion/react'
import React from 'react'
import LeshenColumns from './LeshenColumns.styles'
import LeshenSplitModule from './LeshenSplitModule.styles'
import Typography from './Typography.styles'
import Button from './Button.styles'
import PackageCard from './PackageCard.styles'

const GlobalStyles = withTheme(() => (
  <>
    <LeshenColumns />
    <LeshenSplitModule />
    <Typography />
    <Button />
    <PackageCard />
  </>
))

export default GlobalStyles
