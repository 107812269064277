import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const ButtonStyles = withTheme(() => (
  <Global
    styles={css`
      // unfortunatly these styles are necessary as leshen
      // only has theme-level values for button border radius
      // of 'min,' 'max,' and 'none'

      .leshen-phone-cta,
      .leshen-link-button {
        border-radius: 8px !important;
      }
    `}
  />
))

export default ButtonStyles
