/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
  This brand theme will get imported into the site theme and the two will be merged using lodash.merge.
*/
module.exports = {
  colors: {
    primary: '#00002D',
    secondary: '#26B170',
    tertiary: '#3A8CAF',
    dark: '#00002D',
    light: '#F2F2F4',
    warning: '#ff7928',
    error: '#ff3068',
    success: '#2de6bc',
    darkGray: '#231F20',
    background1: '#FFFEEF',
    background2: '#F3F4E2',
    background3: '#F7941D',
    background4: '#931D69',
    background5: '#4C0B35',
    background6: '#00002D',
  },
  gradients: {
    primary: 'linear-gradient(to right, #26B170, #26B170)',
    secondary: 'linear-gradient(to right, #26B170, #26B170)',
    tertiary: 'linear-gradient(to right, #26B170, #26B170)',
  },
  alternateBgColor: true,
  fonts: {
    headings: {
      family: 'new-spirit, sans-serif',
      sm: {
        size: [24, 40],
        lineHeight: [28, 48],
        weight: 400,
      },
      md: {
        size: [28, 48],
        lineHeight: [32, 56],
        weight: 400,
      },
      lg: {
        size: [32, 52],
        lineHeight: [38, 60],
        weight: 400,
      },
    },
    subheadings: {
      family: 'figtree, sans-serif',
      sm: {
        size: [18, 20],
        lineHeight: [24, 28],
        weight: 400,
      },
      md: {
        size: [20, 24],
        lineHeight: [24, 32],
        weight: 400,
      },
      lg: {
        size: [22, 24],
        lineHeight: [24, 36],
        weight: 400,
      },
    },
    body: {
      family: 'figtree, sans-serif',
      legal: {
        size: 12,
        lineHeight: 16,
      },
      md: {
        size: 16,
        lineHeight: 22,
        weight: 400,
      },
    },
  },
  backgroundColors: {
    dark: {
      textColor: '#F2F2F4',
    },
    primary: {
      textColor: '#F2F2F4',
    },
    secondary: {
      textColor: '#F2F2F4',
    },
    light: {
      textColor: '#00002D',
    },
  },
  components: {
    ListItem: {
      icon: 'Lens',
    },
    Price: {
      color: '#00002D',
      dollarSign: {
        size: '24px',
        position: 'top', // top, bottom (default), center
        offsetY: '7px',
        weight: 'normal', // normal, bold
      },
      dollar: {
        weight: 'normal',
      },
      cents: {
        weight: 'normal',
      },
      duration: {
        position: 'bottom', // top, bottom (default), center, underCents, underPrice
        weight: 'normal',
        offsetY: '-9px',
      },
    },
    PackageCard: {
      hideIcons: false,
      label: {
        inside: true,
        backgroundColor: '#00002D',
        color: '#ffffff',
        radius: 'max',
      },
      stroke: {
        width: '1px',
        style: 'solid',
        color: '#00002D',
      },
      shadow: '0',
      backgroundColor: '#FFFFFF',
      radius: '24px',
      color: '#00002D',
      title: {
        color: '#00002D',
      },
    },
    Button: {
      variants: {
        primary: {
          color: 'white',
          hoverColor: 'white',
        },
        secondary: {
          color: 'white',
        },
        tertiary: {
          color: 'white',
        },
        dark: {
          color: 'white',
        },
        light: {
          color: 'black',
        },
        gradients: {
          primary: {
            color: 'white',
          },
          secondary: {
            color: 'white',
          },
          tertiary: {
            color: 'white',
          },
        },
      },
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '4px',
}
