import * as React from 'react'
import { css, Global, withTheme } from '@emotion/react'

const PackageCardStyles = withTheme(() => (
  <Global
    styles={css`
      // unfortunatly these styles are necessary
      // as "label: {inside: true}" does NOT work at the theme level …
      // not sure it ever actually has been used anywhere on any of our sites, TBH

      .package-label {
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        border-radius: 24px !important;
        margin: 0 !important;
      }

      .leshen-price-amount-wrapper .leshen-price-dollar,
      .leshen-price-amount-wrapper .leshen-price-change,
      .leshen-price-amount-wrapper .leshen-price-duration {
        font-family: figtree !important;
      }
    `}
  />
))

export default PackageCardStyles
