import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const PackageCardStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-columns {
        &.leshen-columns-card {
          .leshen-column-wrapper {
            border-radius: 8px;
          }
        }
      }
    `}
  />
))

export default PackageCardStyles
